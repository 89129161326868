import {defineStore} from "pinia";
import {ModuleStoreStateProps} from "@/models";


export const useModuleStore = defineStore("module", {
  state: (): ModuleStoreStateProps => ({
    confirmFlag: false,
    confirmText: '',
    confirmNotice: [],
    confirmButtonText: '확인',
    confirmHideCancelButton: false,
    confirmFunc: () => {
    },

    warningFlag: false,
    warningText: '',
    warningButtonText: '확인',


    toastFlag: false,
    toastText: '',
    toastType: '',

    mediaType: null,
    mediaUrl: null,
    mediaFlag: false
  }),
  getters: {},
  actions: {
    openConfirm(text: string, func: () => void, notice?: string[], buttonText?: string, hideFlag? :boolean) {
      this.confirmText = text
      this.confirmButtonText = buttonText || '확인'
      this.confirmNotice = notice ?? []
      this.confirmFunc = func
      this.confirmFlag = true
      this.confirmHideCancelButton = hideFlag ?? false
    },
    saveConfirm() {
      this.confirmFunc();
      this.confirmFlag = false
    },
    openWarning(text: string, buttonText?: string) {
      this.warningText = text
      this.warningButtonText = buttonText || '확인'
      this.warningFlag = true
    },
    openToast(text: string, type?: string) {

      if (!type) {
        this.toastType = 'default'
      } else {
        this.toastType = type
      }
      this.toastText = text
      this.toastFlag = true


      // setTimeout(() => {
      //   this.snackbarFlag = false
      // }, 5000)
    },
    changeMediaFlag() {
      this.mediaFlag = !this.mediaFlag
    }

  }
})
