import {GameTeamStoreStateProps} from "@/models";
import {defineStore} from "pinia";
import {apiRequest} from "@/repositories";
import cloneDeep from "lodash-es/cloneDeep";


export const useGameTeamStore = defineStore("gameTeam", {
    state: (): GameTeamStoreStateProps => ({
        items: [],
        originItems: [],

        create_items: [],
        delete_ids: [],
        update_items: [],

        initItems: [],

        last_game_team_idx: 0,

        messageTeamItems: [
            {game_team_id: 0, name: '전체'}
        ],

        pageNumber: 0,
        query: {
            page: 1,
            perPage: 50,
            name: '',
        },

        pagination: {
            count: 0,
            next_flag: false,
            page: 1,
            page_numbers: [1],
            per_page: 1,
            prev_flag: false
        },

        count: 0,
        listLoading: false,

        setting_step: 0,
        form: null,
        formOrigin: null,
        formLoading: false,
        formSaving: false,

        pleaId: '',
        game_name: '',
        options: [],
    }),
    getters: {},
    actions: {
        initForm() {
            this.form = {
                name: ''
            };
            this.formOrigin = cloneDeep(this.form)
        },
        resetForm() {
            this.form = cloneDeep(this.formOrigin);
        },
        resetItems() {
            this.items = cloneDeep(this.originItems);
        },

        async onList(gameId: number): Promise<void> {
            this.listLoading = true
            return apiRequest.get({
                url: `/api/game/${gameId}/team`,
                onError: false,
            })
                .then((data) => {
                    const {apiData} = data;

                    this.items = apiData.game_team_items.map((ele: any, idx: number) => {
                        return {
                            ...ele,
                            idx: idx + 1,
                        }
                    });

                    this.originItems = apiData.game_team_items.map((ele: any, idx: number) => {
                        return {
                            ...ele,
                            idx: idx + 1,
                        }
                    });

                    this.last_game_team_idx = apiData.last_game_team_idx


                    this.messageTeamItems = [
                        {game_team_id: 0, name: '전체'},
                        ...apiData.game_team_items
                    ];
                    this.game_name = apiData.game_name

                    this.setting_step = apiData.setting_step
                    this.pleaId = apiData.id

                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    this.pageNumber = Math.ceil(this.items.length / Number(this.query.perPage))
                    this.listLoading = false;
                })
        },

        async onCreate(gameId: number, teamNumber: number | null) {
            this.formSaving = true;
            return apiRequest.post({
                url: `/api/game/${gameId}/team`,
                data: {num_teams: teamNumber}
            })
                .then((data) => {
                    this.formOrigin = cloneDeep(this.form);

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })
        },

        async onDelete(gameId: number, gameTeamId: number) {
            this.formSaving = true;
            return apiRequest.delete({
                url: `/api/game/${gameId}/team/${gameTeamId}`,
            })
                .then((data) => {
                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })

        },

        async onDeleteAll(gameId: number) {
            this.formSaving = true;
            return apiRequest.delete({
                url: `/api/game/${gameId}/team/all`,
            })
                .then((data) => {
                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })

        },


        async onUpdateTeamAll(gameId: number) {

            this.formSaving = true;
            return apiRequest.put({
                url: `/api/game/${gameId}/team/all`,
                data: {
                    create_items: this.create_items,
                    delete_ids: this.delete_ids,
                    update_items: this.update_items
                },
            })
                .then((data) => {
                    this.formOrigin = cloneDeep(this.form);

                    this.items = []
                    this.originItems = []
                    this.create_items = []
                    this.update_items = []
                    this.delete_ids = []

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })
        },

    }

})
