import {defineStore} from "pinia";
import {apiRequest} from "@/repositories";
import cloneDeep from "lodash-es/cloneDeep";
import {QuestionStoreStateProps} from "@/models/question";


export const useQuestionStore = defineStore("question", {
  state: (): QuestionStoreStateProps => ({
    items: [],
    itemsMap: {},
    query: {
      page: 1,
      perPage: 50,
      name: '',
    },
    pagination: {
      count: 0,
      next_flag: false,
      page: 1,
      page_numbers: [1],
      per_page: 1,
      prev_flag: false
    },

    count: 0,
    listLoading: false,

    form: null,
    formOrigin: null,
    formLoading: false,
    formSaving: false,
  }),
  getters: {},
  actions: {
    initForm() {
      this.form = {
        question_id: 0,
        question: '',
        answer: [],
        question_type: 1,
        answer_type: 1,
        answer_choice_items: [
          {}, {}, {}, {},
        ],
        has_answer_choice_items_image: false,
        icon_id: 0,
        question_media:[
          {}
        ],
        question_set_id: 0,
        class_type: 1,
        interference_type: 1
      };
      this.formOrigin = cloneDeep(this.form)
    },
    resetForm() {
      this.form = cloneDeep(this.formOrigin);
    },
    async onList(questionSetId: number): Promise<void> {
      this.itemsMap[Number(questionSetId)] = {
        items: [],
        loading: true
      }
      this.listLoading = true
      return apiRequest.get({
        url: `/api/question-set/${questionSetId}/question`,
        onError: false,
        params: this.query,
      })
        .then((data) => {
          //  api data 에서 뭐가 떨어질지 모르겠음 , 일단은 값이 있는 res 사용
          const {apiData} = data;

          this.itemsMap[Number(questionSetId)].items = apiData.question_items

          this.pagination = apiData.pagination;

          return Promise.resolve(apiData);
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => {
          this.listLoading = false;
          this.itemsMap[Number(questionSetId)].loading = false;
        })
    },

    async onCreate(questionSetId: number) {

      this.formSaving = true;
      return apiRequest.post({
        url: `/api/question-set/${questionSetId}/question`,
        data: this.form
      })
        .then((data) => {
          this.formOrigin = cloneDeep(this.form);

          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })
    },

    async onGet(questionSetId: number, questionId: number): Promise<void> {
      this.formLoading = true
      return apiRequest.get({
        url: `/api/question-set/${questionSetId}/question/${questionId}`,
        onError: false,
      })
        .then((data) => {
          const {apiData} = data;

          if (apiData.question.question_media.length < 4) {
            this.form = {
              ...apiData.question,
              question_media: [...apiData.question.question_media]
            };
          } else {
            this.form = apiData.question
          }


          this.formOrigin = cloneDeep(apiData.question);

          return Promise.resolve(apiData);
        })
        .catch((error) => {
          this.form = null;
          this.formOrigin = null;

          return Promise.reject(error);
        })
        .finally(() => {
          this.formLoading = false;
        })
    },

    async onUpdate(questionSetId: number, questionId: number) {
      this.formSaving = true;
      return apiRequest.put({
        url: `/api/question-set/${questionSetId}/question/${questionId}`,
        data: this.form,
      })
        .then((data) => {
          this.formOrigin = cloneDeep(this.form);

          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })
    },

    async onDelete(questionSetId: number, questionId: number) {
      this.formSaving = true;
      return apiRequest.delete({
        url: `/api/question-set/${questionSetId}/question/${questionId}`,
      })
        .then((data) => {
          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })

    }

  }

})
